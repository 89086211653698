<template>
    <div class="modalContainer">
        <div class="modalSubcontainer">
            <div class="modalTitle">
                Ajouter un commentaire
            </div>
            <hr>
            <div class="modalContent">
                <span>{{ user.character }}: </span>
                <textarea v-model="comment" placeholder="Ex: ¡¿ HOLA QUÉ TÀL ?! "/>
            </div>
            <div class="buttons">
                <button @click="$emit('close', false)">Annuler</button>
                <button @click="submit()">Commenter</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";

    export default {
        name: 'CommentModal',
        props: {
            applyid: Number
        },
        data() {
            return {
                comment: ''
            }
        },
        computed: {
            ...mapGetters({
                user: "getUser",
            }),
        },
        methods: {
            ...mapActions({
                setComment: "setComment",
                sessionStillValid: "sessionStillValid",
            }),
            submit() {
                this.setComment({
                    apply_id: this.applyid,
                    comment: this.comment
                }).then(() => {
                    this.$emit('close', true)
                })
            }
        },
        mounted() {
            this.sessionStillValid()
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

hr {
    border: 0;
    height: 2px;
    background-image: linear-gradient(to right, rgba(119, 115, 115, 0.75), rgba(49, 49, 49, 0));
    min-width: 100px;
    max-width: 500px;
    margin-left: 0;
}

button {
    font-family: "Realvirtue", Roboto;
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: bold;
    border-radius:0.12em;
    border-color: #ff481d;
    color: #ff481d;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0);
    cursor: pointer;
    transition: all 100ms ease-in-out;
    margin-left: 10px;
}

button:hover {
    color: #2c3e50;
    background-color: #ff481d;
}

.modalContainer {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(0,0,0,0.7);
}

.modalSubcontainer {
    width: 100%;
    padding: 0 20px 10px 20px;
    min-height: 50px;
    background: linear-gradient(133deg, rgba(0,0,0,1) 10%, rgb(13, 21, 83, 1) 38%, rgba(0,0,0,1) 90%);
    flex-wrap: wrap;
    position: relative;
    max-width: 820px;
    margin: auto;
    border-radius: 2px;
}

.modalContent {
    display: flex;
    align-items: start;
}

.modalTitle,
.modalContent span {
    font-family: "Realvirtue", Roboto;
    font-size: 25px;
    letter-spacing: 3px;
    color: #ff481d;
    font-weight: bold;
    text-align: left;
    position: relative;
}

.modalContent textarea {
    margin-left: 20px;
    overflow: auto;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 25%);
    border-radius: 2px;
    width: 100%;
    min-height: 150px;
    border: none;
    padding: 10px 20px;
    resize: vertical;
    background: rgb(20, 32, 122);
    color: inherit;
}

.buttons {
    margin-top: 10px;
    text-align: right;
}
</style>
