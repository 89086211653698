<template>
    <div class="applyoperations">
        <span class="back" @click="close()">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
            </svg>
            &nbsp;Retour
        </span>
        <span class="applytitle">Statut : {{ formatState(apply.state) }}</span>
        <div class="applyactions" v-if="user.isAdmin">
            <!-- reject -->
            <div v-if="apply.state != 'rejected' && apply.state != 'validated'">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="red" class="bi bi-x-square" viewBox="0 0 16 16"
                    @click="submit('reject')">
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <div class="hover">Rejeter</div><div class="triangle"/>
            </div>
            <!-- prevalidate -->
            <div v-if="apply.state == 'draft'">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-bookmark-check" viewBox="0 0 16 16"
                    @click="submit('validateDraft')">
                    <path fill-rule="evenodd" d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                </svg>
                <div class="hover">Valider la candidature</div><div class="triangle"/>
            </div>
            <!-- apply -->
            <div v-if="apply.state == 'prevalidated'">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-calendar-week" viewBox="0 0 16 16"
                    @click="submit('validateMeeting')">
                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                </svg>
                <div class="hover">Valider l'entretien</div><div class="triangle"/>
            </div>
            <!-- validate -->
            <div v-if="apply.state == 'apply'">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="green" class="bi bi-hand-thumbs-up" viewBox="0 0 16 16"
                    @click="submit('validateApplyPeriod')">
                    <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
                </svg>
                <div class="hover">Valider la période d'apply</div><div class="triangle"/>
            </div>
            <!-- draft -->
            <div v-if="apply.state != 'draft'">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ff9e00" class="bi bi-pencil-square" viewBox="0 0 16 16"
                    @click="submit('backToDraft')">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                </svg>
                <div class="hover">Remettre en brouillon</div><div class="triangle"/>
            </div>
        </div>
    </div>
    <div class="applycontent">
        <div class="applyhead">
            <span class="applytitle">{{ apply.character }}, le {{ formatDate(apply.sheet_created_at) }}</span><br/>
        </div>

        <div>
            <span class="applytitle">Personnage</span><br/>
            <div>
                <span class="applyquestion">-Présentez-le en détail, vos manières de le jouer, et pourquoi ?</span><br/>
                <span class="applyanswer" v-html="apply.gameplay"/><br/>
            </div>
            <div>
                <span class="applyquestion">-Quelles sont vos sources d'informations concernant l'optimisation des vos personnages ?</span><br/>
                <span class="applyanswer" v-html="apply.learning"/><br/>
            </div>
            <div>
                <span class="applyquestion">-Lien Warcraftlog ...</span><br/>
                <span class="applyanswer" v-html="apply.warcraftlog"/><br/>
            </div>
            <div>
                <span class="applyquestion">-Avez-vous des alts ? Seriez-vous prêt à les jouer en progress ?</span><br/>
                <span class="applyanswer" v-html="apply.alts"/><br/>
            </div>
        </div>

        <div>
            <span class="applytitle">Raid</span><br/>
            <div>
                <span class="applyquestion">-Parlez nous de vos anciennes expériences en raid, Pvp , MM+ ...</span><br/>
                <span class="applyanswer" v-html="apply.exp"/><br/>
            </div>
            <div>
                <span class="applyquestion">-Screenshot de votre interface en raid</span><br/>
                <span class="applyanswer" v-html="apply.screenshot"/><br/>
            </div>
            <div>
                <span class="applyquestion">-Quels Addons/Macro utilisez-vous lors des raids ?</span><br/>
                <span class="applyanswer" v-html="apply.addons"/><br/>
            </div>
        </div>

        <div>
            <span class="applytitle">Plus...</span><br/>
            <div>
                <span class="applyquestion">-Qui êtes-vous ? Présentez-vous ! Votre âge, vos passions ...</span><br/>
                <span class="applyanswer" v-html="apply.presentation"/><br/>
            </div>
            <div>
                <span class="applyquestion">-Pourquoi voulez-vous nous rejoindre ? Que pensez-vous apporter à la guilde ? Qu'attendez-vous d'elle ?</span><br/>
                <span class="applyanswer" v-html="apply.motivation"/><br/>
            </div>
            <div>
                <span class="applyquestion">-Un message à nous faire passer ? Exprimez-vous librement !</span><br/>
                <span class="applyanswer" v-html="apply.talk"/><br/>
            </div>
        </div>
        <button @click="comment(false)">Commenter</button>
        <CommentCard 
            :commentsProp="apply.comments" 
            :formatDate="formatDate"
            :urlify="urlify"
        />
    </div>
    <CommentModal 
        :applyid="apply.id"
        @close="comment"
        v-if="showCommentModal"
    />
</template>

<script>
import CommentCard from '../components/CommentCard.vue'
import CommentModal from '../components/modal/CommentModal.vue'
import { mapActions, mapGetters } from "vuex"

export default {
    name: 'ApplyCard',
    emits: ['backFromApply'],
    components: {
        CommentCard,
        CommentModal,
    },
    props: {
        applyProp: Object,
        formatDate: Function,   
        formatState: Function
    },
    data() {
        return {
            apply: this.applyProp,
            showCommentModal: false,
            needToRefreshApplies: false
        }
    },
    computed: {
        ...mapGetters({
            user: "getUser"
        }),
    },
    methods: {
        ...mapActions({
            getApply: "getApply",
            updateApply : "updateApply",
            sessionStillValid: "sessionStillValid",
        }),
        urlify(texts, type) {
            //type apply OU comment
            // le traitement est différent pour chaque
            const regex = new RegExp(/^(http(s)?:\/\/)\S*/g)
            if (type == 'apply') {
                for (let [key, text] of Object.entries(texts)) {
                    if (typeof text == 'string') {
                        text = text.replace(regex, (url) => {
                            return '<a href="' + url + '" target="_blank" class="applyanswerlink">' + url + '</a>'
                        })
                    }
                    texts[key] = text
                }
            } else if (type == 'comment') {
                for (let [key, text] of Object.entries(texts)) {
                    console.log(key)
                    text.comment = text.comment.replace(regex, (url) => {
                        return '<a href="' + url + '" target="_blank" class="commentlink">' + url + '</a>'
                    })
                }
            }
            return texts
        },
        comment(refresh) {
            this.showCommentModal = !this.showCommentModal
            if (refresh) {
                this.refreshApply()
            }
        },
        refreshApply() {
            this.getApply({
                id: this.apply.id
            }).then((e) => {
                this.apply = this.urlify(e.data, 'apply')
                this.needToRefreshApplies = true
            });
        },
        submit(action) {
            let applyState = ''
            switch (action) {
                case 'reject':
                    applyState = 'rejected'
                    break;
                case 'validateDraft':
                    applyState = 'prevalidated'
                    break;
                case 'validateMeeting':
                    applyState = 'apply'
                    break;
                case 'validateApplyPeriod':
                    applyState = 'validated'
                    break;
                case 'backToDraft':
                    applyState = 'draft'
                    break;
                default:
                    applyState = 'none'
            }
            this.updateApply({
                id: this.apply.id,
                state: applyState
            }).then(() => {
                this.refreshApply()
            })
        },
        close() {
            this.$emit('backFromApply', this.needToRefreshApplies)
        }
    },
    mounted() {
        this.sessionStillValid()
        this.apply = this.urlify(this.apply, 'apply')
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
button {
    font-family: "Realvirtue", Roboto;
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: bold;
    border-radius:0.12em;
    border-color: #ff481d;
    color: #ff481d;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0);
    cursor: pointer;
    transition: all 100ms ease-in-out;
}

button:hover {
    color: #2c3e50;
    background-color: #ff481d;
}

.back {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.applyoperations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.applyoperations > * {
    margin: 0 10px;
}

.applyactions {
    display: flex;
}

.applyactions > div {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.applyactions svg{
    margin: 0 10px;
    transition: all 100ms ease-in-out;
    cursor: pointer;
}

.applyactions svg:hover {
    fill: #ff481d;
}

.applyactions > div .hover,
.applyactions > div .triangle {
    height: 54px;
    position: absolute;
    top: 50%;
    right: 60px;
    transform: translateY(-50%);
    display: none;
    text-align: center;
    font-family: "Realvirtue", Roboto;
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: bold;
    border: solid 2px;
    border-radius:0.12em;
    border-color: #ff481d;
    box-sizing: border-box;
    color: #ff481d;
    background-color: #0c144f;
    padding: 10px;
    transition: all 100ms ease-in-out;
    white-space: nowrap;
}

.applyactions > div .triangle {
    height : 0;
    width : 0;
    border: none;
    border-top : 27px solid transparent;
    border-bottom : 27px solid transparent;
    border-left : 10px solid #ff481d;
    background-color: rgba(0,0,0,0);
    border-radius: 0;
    padding: 0;
    right: 50px;
}

.applyactions > div > svg:hover ~ .hover,
.applyactions > div > svg:hover ~ .triangle {
    display: block;
}

.applycontent {
    width: 90%;
    margin: auto;
}

.applycontent >>> .applyanswerlink{
    font-weight: bold;
    color: #334c64;
}

.applycontent >>> .applyanswerlink:hover{
    transition: color 100ms ease-in-out;
    color: #ff481d;
}

.applytitle {
    font-family: "Realvirtue", Roboto;
    font-size: 25px;
    letter-spacing: 3px;
    color: #ff481d;
    font-weight: bold;
}

.applyhead {
    text-align: center;
}

.applyhead .applytitle {
    font-size: 30px;
}

.applycontent > div > div {
    margin: 10px 0;
}

.applyquestion {
    font-weight: bold;
}

.applyanswer {
    margin-left: 15px;
}


@media screen and (max-width: 768px) {
    .applyactions > div > svg:hover ~ .hover,
    .applyactions > div > svg:hover ~ .triangle {
        display: none;
    }
}

</style>
