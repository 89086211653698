import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Applies from '../views/Applies.vue'

const routes = [
  {
    path: '/:code?',
    name: 'Home',
    component: Home,
  },
  {
    path: '/apply',
    name: 'Applies',
    component: Applies,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
