<template>
<div>
    <div class="presentationLine">
        <img alt="Salutation de Draenaz" src="../assets/hi.png"/>
        <div>
            <p class="title">Coucou ! Tu veux voir... mon apply ?</p>
            <p>Si tu es ici, fier forban, c'est qu'on t'a envoyé pour <strong>commenter les applys.</strong></p>
            <p>Alors, évidemment, il n'y a que les tras... membres du <strong>roster</strong> qui ont le <strong>droit</strong>, que dis-je, le privilège de lâcher un bon gros CHEH sur une apply (sauf Hartann, pour qui les rôles sont inversés et qui se fait trash par les dits applys).</p>
        </div>
    </div>
    <div class="presentationLine">
        <div>
            <p class="title">Une simplicité qui défie les lois du cycle BM !</p>
            <p>Exit les vieux channnels discord, on a maintenant un beau site tout neuf, hyper simple à utiliser où il n'y a qu'a <strong>se connecter avec Battle.net</strong> pour faire invalider ce vilain apply qui vous met a l'amende sur les parses !</p>
        </div>
        <img alt="Admiration de Draenaz" src="../assets/nicesite.png"/>
    </div>
    <div class="presentationLine">
        <img alt="Beg de Draenaz" src="../assets/svp.png"/>
        <div>
            <p class="title">S'il vous pliz...</p>
            <p>Alors viens avec nous et n'hésites pas à lacher tes plus beaux "+1", "first", et "Feignus, arrête de mettre 1M en roll au gambler, c'est beaucoup trop, je ne peux pas me permettre", et see you soon in-game (mais commentez quand même avant) !</p>
        </div>
    </div>
</div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        name: 'Home',
        data () {
            return {
                bearerCode: ''
            }
        },
        methods: {
            ...mapActions({
                createBnetToken: "createBnetToken",
                getBnetApiData: "getBnetApiData",
                login: "login",
                logout: "logout",
                characters: "characters",
                sessionStillValid: "sessionStillValid"
            }),
            createToken() {
                // createBnetToken va servir à récupérer le Bearer
                this.createBnetToken({
                    redirect_uri: process.env.VUE_APP_BNET_REDIRECT_URI,
                    scope: process.env.VUE_APP_BNET_SCOPE,
                    grant_type: 'authorization_code',
                    code: this.$route.query.code // Le fameux code dans l'url
                }).then((e) => {
                    // Quand on a ce fameux Bearer, on l'envoie au back
                    this.bearerCode = e.data.access_token
                    this.login({
                        bearer: this.bearerCode
                    }).then(() => {
                        this.characters()
                    })
                })
            },
            logoutServer() {
                // On envoie le Bearer au login, pour faire le text de connexion, et recup le btag
                this.logout()
            }
        },
        mounted() {
            this.sessionStillValid()
            if (this.$route.query.code && this.bearerCode == '') {
                this.createToken()
            }
        }
    }
</script>

<style scoped>

p {
    font-size: 20px;
}

.title {
    font-family: "Realvirtue", Roboto;
    font-size: 30px;
    letter-spacing: 3px;
    font-weight: bold;
    color: #ff481d;
}

.presentationLine {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.presentationLine > * {
    margin: 0 20px;
}

@media screen and (max-width: 768px) {
    .presentationLine img {
        display: none;
    }
}

</style>
