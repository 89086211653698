import { createStore } from 'vuex'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate"

const apiServer = axios.create({
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Access-Control-Allow-Credentials': true,
    },
})

const token = Buffer.from(process.env.VUE_APP_BNET_CLIENT_ID + ':' + process.env.VUE_APP_BNET_CLIENT_SECRET, 'utf8').toString('base64')
const apiOauth = axios.create({
    headers: {
        Accept: 'application/json',
        Authorization: 'Basic ' + token,
        'content-Type': 'application/x-www-form-urlencoded'
    },
})


const apiBnet = axios.create({
    headers: {
        Accept: 'application/json',
    },
})

export default createStore({
    state: {
        user: {
            btag: '',
            character: '',
            isOnline: false,
            isAdmin: false,
        }
    },
    getters: {
        getUser: state => {
            return state.user
        }
    },
    mutations: {
        setUser(state, payload) {
            state.user.btag = payload.bnet_tag
            state.user.character = payload.character
            state.user.isOnline = true
            state.user.canView = false
        },
        resetUser(state) {
            state.user.bnet = ''
            state.user.character = ''
            state.user.isOnline = false
            state.user.isAdmin = false
            state.user.canView = false
        },
        setCharacter(state, payload) {
            state.user.character = payload.character
            state.user.isAdmin = payload.rank < 4 ? true : false
            state.user.canView = payload.rank < 6 ? true : false
        },
    },
    actions: {
        /*************** SESSION ACTIONS *******************/
        getCSRF() {
            return new Promise ((resolve, reject) => {
                apiServer.get(process.env.VUE_APP_CSRF + 'sanctum/csrf-cookie')
                    .then((response) => {
                        resolve(response)
                    }).catch((error) => {
                        reject(error)
                    });
            })
        },
        login(context, data) {
            return new Promise ((resolve, reject) => {
                this.dispatch('getCSRF').then(() => {
                    apiServer.post(process.env.VUE_APP_API + 'create-account', data)
                    .then((response) => {
                        context.commit('setUser', response.data.data)
                        resolve(response)
                    }).catch((error) => {
                        reject(error)
                    })
                });
            })
        },
        createBnetToken(context, data) {
            // Oauth attend un format application/x-www-form-urlencoded, qui n'est donc pas du json, mais le format des formulaires clé = valeur
            // On va donc transformer ce json en formdata
            let formData = new FormData();
            formData.append('redirect_uri', data.redirect_uri);
            formData.append('scope', data.scope);
            formData.append('grant_type', data.grant_type);
            formData.append('code', data.code);
            return new Promise ((resolve, reject) => {
                apiOauth.post('https://eu.battle.net/oauth/token', formData)
                .then((response) => {
                    resolve(response)
                }).catch((error) => {
                    for(let i in error.response.data.errors) {
                        for(let j in error.response.data.errors[i]) {
                            reject(error.response.data.errors[i][j])
                        }
                    }
                })
            })
        },
        getBnetApiData(context, data) {
            return new Promise ((resolve, reject) => {
                apiBnet.get(data.link + 'access_token=' + data.token)
                .then((response) => {
                    resolve(response)
                }).catch((error) => {
                    for(let i in error.response.data.errors) {
                        for(let j in error.response.data.errors[i]) {
                            reject(error.response.data.errors[i][j])
                        }
                    }
                })
            })
        },
        logout(context) {
            return new Promise ((resolve, reject) => {
                this.dispatch('getCSRF').then(() => {
                    this.dispatch('sessionStillValid')
                    apiServer.post(process.env.VUE_APP_API + 'sign-out')
                    .then((response) => {
                        context.commit('resetUser')
                        resolve(response)
                    }).catch((error) => {
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
                });
            })
        },
        sessionStillValid(context) {
            const name = 'laravel_session=';
            const cDecoded = decodeURIComponent(document.cookie); //to be careful
            const cArr = cDecoded.split('; ');
            let res;
            cArr.forEach(val => {
                if (val.indexOf(name) === 0) res = val.substring(name.length);
            })
            if (res == undefined) {
                context.commit('resetUser')
                return false
            }
            return true
        },
        /*************** IG INFORMATIONS *******************/
        guild() {
            return new Promise ((resolve, reject) => {
                this.dispatch('getCSRF').then(() => {
                    this.dispatch('sessionStillValid')
                    apiServer.get(process.env.VUE_APP_API + 'guild')
                    .then((response) => {
                        resolve(response)
                    }).catch((error) => {
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
                });
            })
        },
        characters(context) {
            return new Promise ((resolve, reject) => {
                this.dispatch('getCSRF').then(() => {
                    this.dispatch('sessionStillValid')
                    apiServer.get(process.env.VUE_APP_API + 'characters')
                    .then((response) => {
                        context.commit('setCharacter', response.data.data)
                        resolve(response)
                    }).catch((error) => {
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
                });
            })
        },


        /*************** COMMENT ACTIONS *******************/
        getApplies() {
            return new Promise ((resolve, reject) => {
                this.dispatch('getCSRF').then(() => {
                    this.dispatch('sessionStillValid')
                    apiServer.get(process.env.VUE_APP_API + 'apply')
                    .then((response) => {
                        resolve(response)
                    }).catch((error) => {
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
                });
            })
        },
        getApply(context, data) {
            return new Promise ((resolve, reject) => {
                this.dispatch('getCSRF').then(() => {
                    this.dispatch('sessionStillValid')
                    apiServer.get(process.env.VUE_APP_API + 'apply/' + data.id)
                    .then((response) => {
                        resolve(response)
                    }).catch((error) => {
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
                });
            })
        },
        updateApply(context, data) {
            return new Promise ((resolve, reject) => {
                this.dispatch('getCSRF').then(() => {
                    this.dispatch('sessionStillValid')
                    apiServer.put(process.env.VUE_APP_API + 'apply/' + data.id, data)
                    .then((response) => {
                        resolve(response)
                    }).catch((error) => {
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
                });
            })
        },
        setComment(context, data) {
            return new Promise ((resolve, reject) => {
                this.dispatch('getCSRF').then(() => {
                    this.dispatch('sessionStillValid')
                    apiServer.post(process.env.VUE_APP_API + 'comment', data)
                    .then((response) => {
                        resolve(response)
                    }).catch((error) => {
                        for(let i in error.response.data.errors) {
                            for(let j in error.response.data.errors[i]) {
                                reject(error.response.data.errors[i][j])
                            }
                        }
                    })
                });
            })
        },
    },
    modules: {
    },
    plugins: [createPersistedState()]
})
