<template>
    <div id="nav">
        <router-link to="/" class="navbarFirstElem">
            <img alt="Neterya logo" src="./assets/logo.png">
        </router-link>
        <ul class="navLinks">
            <li>
                <router-link to="/">Neterya</router-link>
            </li>
            <li v-if="user.isOnline && user.canView">
                <router-link to="/apply">Apply</router-link>
            </li>
        </ul>
        <div class="navbarLastElem">
            <span v-if="user.isOnline">
                <span class="bnetConnect">{{ user.btag }}</span><br/>
                <a href="" @click.prevent="disconnect">Déconnexion</a>
            </span>
            <a :href=getBnetLink target="_blank" class="bnetConnect" v-else>Se connecter avec Battle.net</a>
        </div>
        <!---------------------------------------->
        <div class="menu">
            <div class="mobile" v-on:click="showMenu = !showMenu">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="currentColor"
                    class="bi bi-list"
                    viewBox="0 0 16 16"
                >
                    <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    ></path>
                </svg>
                <transition name="menu">
                    <div v-if="showMenu" class="firstLevel">
                        <div class="closeCross">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            fill="currentColor"
                            class="bi bi-x"
                            viewBox="0 0 16 16"
                            >
                            <path
                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                            />
                            </svg>
                        </div>
                        <ul>
                            <li v-if="user.isOnline">
                                {{ user.btag }}
                            </li>
                            <li v-if="user.isOnline">
                                <a href="" class="bnetConnectMobile" @click.prevent="disconnect">Déconnexion</a>
                            </li>
                            <li>
                                <router-link to="/">Neterya</router-link>
                            </li>
                            <li v-if="user.isOnline && user.canView">
                                <router-link to="/apply">Apply</router-link>
                            </li>
                            <li v-if="!user.isOnline">
                                <a :href=getBnetLink class="bnetConnectMobile">Se connecter avec Battle.net</a>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
        </div>
    </div>
    <div class="separationLine"/>
    <router-view class="content"/>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        data() {
            return {
                showMenu: false,
                retour: '',
            };
        },
        computed: {
            ...mapGetters({
                user: "getUser",
            }),
            getBnetLink: function() {
                return 'https://eu.battle.net/oauth/authorize?client_id=' + process.env.VUE_APP_BNET_CLIENT_ID + '&redirect_uri=' + process.env.VUE_APP_BNET_REDIRECT_URI + '&response_type=code&scope=' + process.env.VUE_APP_BNET_SCOPE
            }
        },
        methods: {
            ...mapActions({
                logout: "logout",
                sessionStillValid: "sessionStillValid"
            }),
            disconnect() {
                this.logout()
                this.$router.push({ name: "Home"})
            }
        },
        mounted() {
            this.sessionStillValid()
        }
    }
</script>

<style>
@font-face {
  font-family: "Realvirtue";
  src: local("Realvirtue"),
   url(./fonts/realvirtue.ttf) format("truetype");
}


html,
body,
ul,
li,
a {
    padding: 0;
    margin: 0;
    list-style: none;
    text-decoration: none;
}

body {
    min-height: 100vh;
    background: rgb(0,0,0);
    background: linear-gradient(133deg, rgba(0,0,0,1) 10%, rgb(13, 21, 83) 38%, rgba(0,0,0,1) 90%);
}

hr {
    border: 0;
    height: 2px;
    background-image: linear-gradient(to right, rgba(49, 49, 49, 0), rgba(119, 115, 115, 0.75), rgba(49, 49, 49, 0));
}

span {
    overflow-wrap: break-word;
    display: inline-block;
    max-width: 100%;
}
#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #c9c9c9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#nav {
    font-family: "Realvirtue", Roboto;
    font-size: 30px;
    letter-spacing: 3px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
}

.navbarFirstElem,
.navbarLastElem {
    height: 110px;
    width: 250px;
    display: flex;
    position: relative;
}

.navbarLastElem {
    align-items: center;
}

#nav a:not(.bnetConnect, .bnetConnectMobile) {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active,
#nav a:hover,
.bnetConnect,
.bnetConnectMobile {
    color: #ff481d;
    font-weight: bold;
}

#nav a:hover {
  transition: color 100ms ease-in-out
}

.navLinks {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.separationLine {
    width: 100%;
    height: 2px;
    background-color: white;
}
.content {
    flex-grow: 1;
    max-width: 820px;
    width: 80%;
    margin: 20px auto;
}

.menu {
    display: none;
}

.closeCross {
    height: 60px;
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.menu-enter-active,
.menu-leave-active {
    transition: transform 0.3s;
}

.menu-enter-from {
    transform: translateY(-100%);
}

.menu-leave-to {
    transform: translateY(-100%);
}

.firstLevel {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 99999999;
}

.firstLevel:first-child {
    position: absolute;
    right: 20px;
    top: 20px;
}

.firstLevel li {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.firstLevel li a{
    text-align: center;
}

@media screen and (max-width: 768px) {
    .bnetConnect,
    .navLinks,
    .navbarLastElem {
        display: none;
    }
    
    .menu {
        display: flex;
        align-items: center;
        height: 100%;
        color: white;
    }
}
</style>
