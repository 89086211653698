<template>
    <div class="title"><span>Commentaires</span></div>
    <hr class="commhr">
    <div v-for="comment in comments" :key="comment.id">
        <div class="comment">
            <span class="commentcharacter">{{ comment.user.character }}</span>
            <span class="commentdate">{{ formatDate(comment.created_at) }}</span>
            <span class="commenttext" v-html="comment.comment"/>
        </div>
        <hr>
    </div>
</template>

<script>
import { mapActions } from "vuex"
export default {
    name: 'ApplyCard',
    props: {
        commentsProp: Object,  
        formatDate: Function,
        urlify: Function
    },
    data() {
        return {
            comments: this.urlify(this.commentsProp, 'comment')
        }
    },
    methods: {
        ...mapActions({
            sessionStillValid: "sessionStillValid",
        }),
    },
    mounted() {
        this.sessionStillValid()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.commhr {
    width: 100px;
}

.title,
.commentcharacter,
.commentdate {
    font-family: "Realvirtue", Roboto;
    font-size: 25px;
    letter-spacing: 3px;
    color: #ff481d;
    font-weight: bold;
}
.title{
    text-align: center;
}

.comment {
    min-height: 100px;
    position: relative;
}

.commentcharacter,
.commentdate {
    position: absolute;
    top: 0;
}

.commentcharacter {
    left: 0;
}

.commentdate {
    right: 0;
}

.commenttext {
    display: inline-block;
    margin: 40px 0 0 30px;
}

.comment >>> .commentlink{
    font-weight: bold;
    color: #334c64;
}

.comment >>> .commentlink:hover{
    transition: color 100ms ease-in-out;
    color: #ff481d;
}

</style>
