<template>
    <div v-if="user.isOnline && user.canView">
        <div v-if="applySelected === null">
            <div v-for="apply in filteredApplies" :key="apply.id">
                <div class="applies" @click="selectApply(apply)">
                    <span class="applydate">{{ formatDate(apply.sheet_created_at) }}</span>
                    <span class="applystate">{{ formatState(apply.state) }}</span>
                    <span>{{ apply.character }}</span>
                </div>
                <hr>
            </div>
            <button v-for="index in totalPages" :key="index"
            :class="{'currentPage': page == index}"
            @click="navigate(index)">{{ index }}</button>
        </div>
        <div v-else>
            <ApplyCard 
                :applyProp="applySelected" 
                :formatDate="formatDate"
                :formatState="formatState"
                @backFromApply="backFromApply"
            />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import ApplyCard from '../components/ApplyCard.vue'

    export default {
        name: 'Applies',
        components: {
            ApplyCard,
        },
        data () {
            return {
                applies: [],
                applySelected: null,
                page: 1,
                pagination: 10,
            }
        },
        computed: {
            ...mapGetters({
                user: "getUser",
            }),
            filteredApplies: function() {
                return this.applies.slice((this.page - 1) * this.pagination, this.page * this.pagination)
            },
            totalPages: function() {
                if (this.user.isAdmin) {
                    return Math.ceil(this.applies.length / this.pagination)
                } else {
                    return Math.ceil(this.applies.filter(apply => apply.state == 'prevalidated' || apply.state == 'apply').length / this.pagination)
                }
            }
        },
        methods: {
            ...mapActions({
                getApplies: "getApplies", 
                updateApply: "updateApply",
                sessionStillValid: "sessionStillValid",
            }),
            selectApply(apply = false) {
                if (apply) {
                    this.applySelected = apply
                } else {
                    this.applySelected = null
                }
            },
            formatDate(date) {
                date = new Date(date);
                return date.toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric'})
            },
            formatState(state) {
                switch (state) {
                    case 'draft':
                        return 'A traiter'
                    case 'prevalidated':
                        return 'Nouveau'
                    case 'apply':
                        return 'En apply'
                    case 'validated':
                        return 'Validé'
                    case 'rejected':
                        return 'Rejeté'
                }
            },
            backFromApply(refresh) {
                this.applySelected = null
                if (refresh) {
                    this.refreshApplies()
                }
            },
            navigate(page) {
                this.page = page
            },
            refreshApplies() {
                this.getApplies().then((e) => {
                    this.applies = e.data.sort(function(a,b){
                            return new Date(b.sheet_created_at) - new Date(a.sheet_created_at);
                        })
                })
            },
        },
        mounted() {
            this.sessionStillValid()
            this.refreshApplies()
        }
    }
</script>
<style scoped>

.applies {
    text-align: center;
    padding-top: 10px;
    min-height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.applydate,
.applystate {
    position: absolute;
    top: 0;
    font-family: "Realvirtue", Roboto;
    letter-spacing: 2px;
    color: #ff481d;
    font-weight: bold;
}

.applydate {
    left: 0;
}

.applystate {
    right: 0;
}

.applies {
    cursor: pointer;
}

button {
    font-family: "Realvirtue", Roboto;
    font-size: 20px;
    letter-spacing: 3px;
    font-weight: bold;
    border-radius:0.12em;
    border-color: #ff481d;
    color: #ff481d;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0);
    cursor: pointer;
    transition: all 100ms ease-in-out;
    margin-left: 10px;
}

button:hover,
.currentPage {
    color: #2c3e50;
    background-color: #ff481d;
}
</style>
